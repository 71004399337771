<template>
  <app-dialog
    v-model="model"
    :submitting="status === 'pending'"
    subtitle="Inscrivez vos employé.es à vos sessions à venir en une seule fois."
    :title="title"
    :width="1100"
    @submit="execute"
  >
    <registration-batch-import
      v-if="!hasLines"
      v-model="lines"
      :template="[
        {
          first_name: 'Timothée',
          last_name: 'Chalamet',
          email: 'thimothee@chalamet.com',
          job_title: 'Great hair game actor',
          sessions: '12 27 32',
        },
        {
          first_name: 'Taylor',
          last_name: 'Swift',
          email: 'taylor@swift.com',
          job_title: 'Rock star',
          sessions: '18',
        },
      ]"
    >
      <template #instructions>
        <li>{{ $t("registration.batch_import.template.items.download") }}</li>
        <li>
          Indiquez pour chaque participant.e le/les IDs des sessions
          (<app-button
            size="small"
            variant="link"
            @click="onSessionIdListDownload"
          >
            disponibles ici </app-button
          >) auxquelles vous souhaitez l'inscrire.<br />Ces IDs doivent être
          séparés par un espace si vous en indiquez plusieurs.
        </li>
        <li>{{ $t("registration.batch_import.template.items.validate") }}</li>
      </template>
    </registration-batch-import>

    <div v-else class="grid gap-8">
      <section>
        <h3 class="mb-3 text-sm font-semibold">
          {{
            $t(
              "registration.batch_import.multi_session.entries_per_session_title",
            )
          }}
        </h3>

        <div class="grid gap-4">
          <template
            v-for="(
              {
                session,
                entries,
                hasErrors: sessionHasErrors,
                hasExceededRemainingSeatsLimit,
                effectiveUniqRegistrationsCount,
              },
              index
            ) in entriesPerAvailableSession"
            :key="session.id"
          >
            <app-divider v-if="index > 0" />
            <app-expandable>
              <template #trigger="{ open }">
                <div class="flex items-center gap-4">
                  <multi-session-registration-batch-dialog-session-item
                    class="flex-auto"
                    :effective-registrations-count="
                      effectiveUniqRegistrationsCount
                    "
                    :has-errors="sessionHasErrors"
                    :session="session"
                  />
                  <app-icon
                    class="text-subtle transition"
                    :class="{ 'rotate-180': open }"
                    icon="ph:caret-down"
                    :size="20"
                  />
                </div>
              </template>

              <div class="my-4 ml-14">
                <registration-remaining-seats-limit-exceeded-callout
                  v-if="hasExceededRemainingSeatsLimit"
                  :registrations-count="effectiveUniqRegistrationsCount"
                  :remaining-seats="session.remaining_seats!"
                />

                <registration-batch-session-entries :entries="entries" />
              </div>
            </app-expandable>
          </template>
        </div>
      </section>

      <section v-if="entriesWithoutRelatedSession.length > 0">
        <header class="mb-3">
          <h3 class="text-sm font-semibold">
            {{
              $t(
                "registration.batch_import.multi_session.orphans_entries_title",
              )
            }}
          </h3>
          <p class="text-xs text-subtle">
            {{
              $t(
                "registration.batch_import.multi_session.orphans_entries_subtitle",
              )
            }}
          </p>
        </header>

        <registration-batch-session-entries
          :entries="entriesWithoutRelatedSession"
        />
      </section>
    </div>

    <template v-if="hasLines" #footer>
      <div class="flex items-center justify-between gap-4">
        <div>
          <registration-validator-error-callout
            v-if="hasErrors"
            @reset="reset"
          />
        </div>
        <app-button
          class="shrink-0"
          :disabled="hasErrors || validRegistrationsCount === 0"
          type="submit"
        >
          {{
            $t("registration.validation_cta", {
              count: validRegistrationsCount,
            })
          }}
        </app-button>
      </div>
    </template>
  </app-dialog>
</template>

<script lang="ts" setup>
import { useRegistrationExecutor } from "~/business-areas/registration/composables/registration-executor.hook";
import { useSessionBatchRegistrationValidator } from "~/business-areas/registration/composables/registration-validator.hook";

const { organization } = useCurrentOrganization();
const model = defineModel<boolean>();

const {
  lines,
  availableSessions,
  entriesPerAvailableSession,
  entriesWithoutRelatedSession,
  hasErrors,
  hasLines,
  reset,
  validRegistrationsCount,
} = await useSessionBatchRegistrationValidator({
  organization_id: organization.value!.id,
});

const title = computed(() =>
  hasLines.value ? "Inscription groupée 2/2" : "Inscription groupée 1/2",
);

const { execute, status } = useRegistrationExecutor(
  organization.value!.id,
  entriesPerAvailableSession,
  {
    onSuccess() {
      useDialog("MultiSessionBatchRegistrationDialog").close({
        confirmed: true,
      });
    },
  },
);

function onSessionIdListDownload() {
  return toCsvDownload(
    availableSessions.value?.map((session) => {
      return {
        id: session.id,
        title: session.course.title,
        start_date: useI18nDate(session.start_date!, {
          dateStyle: "short",
          timeStyle: "short",
        }).value,
        place: session.place?.address.label ?? "Online",
      };
    }) ?? [],
    { fileName: "Pollen - Session list" },
  );
}
</script>
